// ** React Imports
import { Suspense, lazy, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { Helmet } from "react-helmet"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider, useDispatch, useSelector } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster, ToastPosition } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import { ContextProvider } from "utility/hooks/useContext"

// ** Lazy load app
const LazyApp = lazy(() => import("./App"))

import { WagmiConfig, createClient, configureChains, chain } from "wagmi"
import { publicProvider } from "wagmi/providers/public"
import "./configs/i18n"
import { CookieExpiracyProvider } from "contexts/CookieExpiracyContext"

const { provider } = configureChains([chain.polygon, chain.polygonMumbai], [publicProvider()])

const client = createClient({
  autoConnect: true,
  provider
})

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <WagmiConfig client={client}>
      <Provider store={store}>
        <CookieExpiracyProvider>
          <ContextProvider>
            <Suspense fallback={<Spinner />}>
              <ThemeContext>
                <LazyApp />
                <Toaster
                  position={themeConfig.layout.toastPosition as ToastPosition}
                  toastOptions={{ className: "react-hot-toast" }}
                />
              </ThemeContext>
            </Suspense>
          </ContextProvider>
        </CookieExpiracyProvider>
      </Provider>
    </WagmiConfig>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
