import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"
import { cookies } from "utility/cookies"
import { getOneHourFromNow } from "utility/DateTimeUtils"

export const fetchJWTMetamask = createAsyncThunk(
  "metamask/JWT",
  async (body: {
    email?: string
    address: string
    network: string
    blockchain: string
    signature: string
    message: string
  }) => {
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/auth/metamask`, body)
      return data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

const session = () => {
  const res = cookies.get('userData')
  if (res?.type === "Metamask") {
    return res
  } return null  
} 

export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: null || session(),
    JWTError: null,
    JWTLoading: false
  },
  reducers: {
    removeSessionMetamask: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      cookies.remove('userData', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTMetamask.pending, (state) => {
        state.JWTLoading = true
      })

      .addCase(fetchJWTMetamask.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        getOneHourFromNow()
        cookies.set('userData', ({ ...payload, expires: new Date(getOneHourFromNow()), type: "Metamask" }), { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN })
      })

      .addCase(fetchJWTMetamask.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})
export const { removeSessionMetamask } = authSlice.actions
export default authSlice.reducer
