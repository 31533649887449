import React, { createContext, useContext, useState } from 'react'

export interface IContext {
    showTable : 'table' | 'benefits'
    setShowTable (string)
    isBenefit : boolean
    setIsBenefit : (hasStoreNFTpurchased: boolean) => void;
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
   
    const [showTable, setShowTable] = useState<'table' | 'benefits'>('table')
    const [isBenefit, setIsBenefit] = useState(false)
    
    return (
        <Context.Provider
            value={{
                showTable,
                setShowTable,
                isBenefit,
                setIsBenefit
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const useGlobalContext = () => {
    const context = useContext(Context)
    if (!context) {
        throw new Error('useGlobalContext must be used within a StoreProvider')
    }
    return context
}
