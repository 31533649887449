import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"

export const fetchToken = createAsyncThunk("token/fetchToken", async (id: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/${id}`)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchBenefits = createAsyncThunk("token/fetchBenefits", async (id: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/benefits/token/${id}`)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchCollection = createAsyncThunk("token/fetchCollection", async (id: string) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchSpecificTokenFromUserId = createAsyncThunk(
  "token/fetchSpecificTokenFromUserId",
  async (payload: any) => {
    const { tokenUuid, jwtAccessToken } = payload
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/owned/${tokenUuid}`, {
      headers: {
        Authorization: `Bearer ${jwtAccessToken}`
      }
    })
    if (Object.keys(data).length === 0) {
      return false
    }
    setTokenOwned(true)
    return true
  }
)

const initialTokenState = {
  id: "",
  name: "",
  collectionId: "",
  communityId: "",
  companyId: "",
  createdAt: "",
  displayMediaType: "",
  displayMediaUrl: "",
  transactionType: "",
  supply: "",
  tokenId: "",
  owners: [],
  metadata: {
    pt: {
      name: "",
      description: "",
      attributes: []
    }
  },
  inventory: ""
}

const initialCollectionState = {
  id: "",
  name: "",
  contract: {
    address: "",
    pattern: "",
    blockchain: ""
  }
}

export const tokenSlice = createSlice({
  name: "tokenSlice",
  initialState: {
    activeTokenMenu: "information",

    token: initialTokenState,
    tokenLoading: false,
    tokenErrorMessage: undefined,
    owned: false,

    collection: initialCollectionState,
    collectionLoading: false,
    collectionErrorMessage: undefined,

    benefits: [],
    benefitsLoading: false,
    benefitsErrorMessage: undefined
  },
  reducers: {
    setActiveTokenMenu: (state, { payload }) => {
      state.activeTokenMenu = payload
      window.sessionStorage.setItem("activeTokenMenu", payload)
    },
    setTokenInventory: (state, { payload }) => {
      state.token.inventory = payload
    },
    setTokenOwned: (state, { payload }) => {
      state.owned = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToken.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchToken.fulfilled, (state, { payload }) => {
        state.tokenLoading = false
        state.token = payload
      })
      .addCase(fetchToken.rejected, (state, { payload }) => {
        state.tokenLoading = false
        state.tokenErrorMessage = payload
      })

      .addCase(fetchSpecificTokenFromUserId.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchSpecificTokenFromUserId.fulfilled, (state, { payload }) => {
        state.tokenLoading = false
        state.owned = payload
      })
      .addCase(fetchSpecificTokenFromUserId.rejected, (state, { payload }) => {
        state.tokenLoading = false
      })

      .addCase(fetchCollection.pending, (state) => {
        state.collectionLoading = true
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.collectionLoading = false
        state.collection = payload
      })
      .addCase(fetchCollection.rejected, (state, { payload }) => {
        state.collectionLoading = false
        state.collectionErrorMessage = payload
      })

      .addCase(fetchBenefits.pending, (state) => {
        state.benefitsLoading = true
      })
      .addCase(fetchBenefits.fulfilled, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefits = payload
      })
      .addCase(fetchBenefits.rejected, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefitsErrorMessage = payload
      })
  }
})

export const { setActiveTokenMenu, setTokenInventory, setTokenOwned } = tokenSlice.actions

export default tokenSlice.reducer
