// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import whitelabelConfig from "./whitelabelConfig"
import token from "../views/token/store"
import auth from "../services/store"
import metamask from "../services/store/metamask"
import loginModal from "@core/components/login-modal/store"
import refresh from "./refresh"

const rootReducer = { navbar, layout, whitelabelConfig, token, auth, metamask, loginModal, refresh }

export default rootReducer
